/** @jsx jsx */
import { ReactElement } from 'react';
import { jsx } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { ListView, ListViewProps } from '@sprinklr/shared-lib';

const ListViewTemplate = ({
  sectionId,
  ...listViewProps
}: ListViewProps): ReactElement => {
  return (
    <Container
      id={sectionId}
      containerSx={{
        pb: ['25px', '0px', '0px'],
        px: ['22px', '80px', '80px'],
        pt: ['20px', '40px', '50px'],
      }}
    >
      <ListView {...listViewProps} />
    </Container>
  );
};

export default ListViewTemplate;
